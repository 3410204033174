import { Ii18nScheme } from './en';

const uk: Ii18nScheme = {
  translation: {
    common: {
      phoneInput: {
        placeholder: '',
      },
      errors: {
        'user not found': 'Ой помилка, Юзера не здайдено',
        'wrong phone code': 'Ой помилка, невірний код',
        isRequired: "{{label}} є обов'язковим",
        unknownTitle: 'Помилка',
        unknownText: 'Упс, щось пішло не так!',
        firstNameError: 'Невірний формат',
        lastNameError: 'Невірний формат',
      },
      date: {
        month: {
          January: 'Січень',
          February: 'Лютий',
          March: 'Березень',
          April: 'Квітень',
          May: 'Травень',
          June: 'Червень',
          July: 'Липень',
          August: 'Серпень',
          September: 'Вересень',
          October: 'Жовтень',
          November: 'Листопад',
          December: 'Грудень',
        },
      },
    },
    signIn: {
      index: {
        header: 'Вітаємо у клубі Mashsh-лаверів!',
        text: 'Це програма лояльності, яка конвертує ваші покупки у гривневі бонуси для подальшого шопінгу в Mashsh.',
        buttonLabel: 'Приєднатись, якщо ще не з нами',
        confirmationLabel: 'Код підтвердження',
      },
      validateOtp: {
        header: 'Будь ласка, введіть верифікаційний код\n',
        text: 'що прийшов вам на вказаний номер телефону\n',
        secondText: 'xxx xxxx {{phoneNumberSlice}}.',
        changePhoneNumber: 'Упс… змінити номер телефону',
        noCode: '',
        resendCode: 'Код не надійшов, повторити відправку',
        waitResend: 'Зачекайте 30 секунд і спробуйте ще',
        buttonLabel: 'Підтвердити',
      },
    },
    signUp: {
      acceptTerms: {
        header: '',
        secondHeader: 'Натискаючи «Прийняти», ви погоджуєтесь з',
        text: 'Окрім накопичених бонусів за покупки, ми зможемо балувати вас додатковими приємностями. Для цього хочемо знати трохи більше про вас.',
        checkboxText: 'Я погоджуюсь з <ln>умовами та політикою конфіденційності</ln>',
        buttonLabel: 'Створити аккаунт',
      },
      setPhone: {
        header: 'Введіть номер телефону',
        text: 'Ми надішлемо вам код підтвердження',
        buttonLabel: 'Відправити код {{countdownTitle}}',
      },
      setProfile: {
        firstNameError: 'Невірний формат',
        firstNameLabel: 'Ім’я',
        lastNameError: 'Невірний формат',
        lastNameLabel: 'Прізвище',
        continueButton: 'Підтвердити',
        skipButton: '',
        header: 'Надайте інформацію про себе',
        cardPreview: 'Попередній вигляд карти',
      },
      setBirthday: {
        header: 'Встановити дату народження',
        continueButton: 'Підтвердити',
        dayLabel: 'Число',
        monthLabel: 'Місяць',
        yearLabel: 'Рік',
      },
      step: {
        Basic: '',
        Profile: 'Введіть ім’я та прізвище',
        SetPhone: '',
        PhoneActivation: '',
        PhoneActivationSuccess: '',
        SetEmail: 'Встановити електронну пошту',
        EmailActivation: 'Електронну пошту активовано',
        SetBirthday: 'Встановити дату народження',
        Completed: 'Профіль',
      },
    },
    profile: {
      title: 'Профіль',
      subtitle: '{{name}}, раді вас бачити!',
      mainText: 'Після кожної покупки вам будуть нараховані бонуси.',
      dateLabel: 'Дійсний до: ',
      downloadCardForIos: 'Скачати картку для iOS',
      downloadCardForAndroid: 'Скачати картку для Android',
      bonusesLabel: 'Ваші бонуси: {{balance}}',
      referral: {
        title: '{{issuerTitle}} реферальна програма',
        text: 'Запросіть своїх друзів та отримуйте бонуси за кожного з них!',
        buttonLabel: 'Запросити',
        copy: 'Скопіювати посилання',
        copied: 'Скопійовано!',
      },
      updateName: 'Змінити ім’я та прізвище',
      updatePhone: 'Змінити номер телефону',
      updateEmail: 'Змінити електронну пошту',
      subscribe: 'Підписатися на сповіщення?',
      logout: 'Вийти',
    },
    changeProfile: {
      title: 'Змінити ім’я та прізвище',
      buttonLabel: 'Змінити',
      firstNameLabel: 'Ім’я',
      lastNameLabel: 'Прізвище',
    },
    changePhoneNumber: {
      title: 'Змінити номер телефону',
      buttonLabel: 'Змінити',
      updateButtonLabel: 'Змінити {{time}}',
    },
    system: {
      otp: {
        required: `Обов'язкове поле`,
        pattern: 'Невірний формат',
        minLength: 'Мінімальна довжина 6 символів',
        maxLength: 'Максимальна довжина 6 символів',
      },
    },
  },
};

export default uk;
