export enum RecordType {
  User = 'user',
  Activation = 'activation',
  Profile = 'profile',
  Login = 'login',
  Card = 'card',
  Referral = 'referral',
  BalanceHistory = 'history',
  Bonuses = 'bonuses',
}
