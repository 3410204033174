import { updateCtx } from './interface-adapters/diContext';
import { store } from './interface-adapters/store/store';
import { logger, loggedRequest } from './interface-adapters/logger';
import { init as initUserAnalyticsService } from './interface-adapters/userAnalyticsService';
import { MessageBus } from '@roketus/web-toolkit';
import { sdk } from '@roketus/loyalty-js-sdk';
import { updateUserDataServiceInit } from './interface-adapters/auth/UpdateUserDataService';
import { getEnv } from './config/env';
import { i18n } from './interface-adapters/i18n/i18n';

export const initDi = () => {
  sdk.init(process.env.REACT_APP_API_URL);
  sdk.logger.setup({
    serviceName: process.env.REACT_APP_NAME as string,
    environment: process.env.REACT_APP_ENVIRONMENT as 'develop' | 'stage' | 'production',
  });
  sdk.user.setIssuer(getEnv().ISSUER_SYS_NAME);

  const messageBus = new MessageBus();
  updateCtx({
    store,
    logger: {
      error: logger.error,
      loggedRequest,
    },
    messageBus,
    userAnalyticsService: initUserAnalyticsService(messageBus),
    sdk,
    i18n,
  });

  updateUserDataServiceInit(store, sdk);
};
