import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { head, isUndefined, last } from 'lodash';
// @ts-ignore
import build from 'redux-object';

import { DbState } from '../../boundries/storeState/DbState';
import { RootState } from '../../boundries/storeState/RootState';
import { RecordType } from './types';
import { ProfileEntity, UserEntity } from '../../domain/entities/user';
import { ActivationEntity } from '../../domain/entities/signup';
import { LoginEntity } from '../../domain/entities/auth';
import { BalanceEntity, CardEntity, ReferralEntity } from '../../domain/entities/card';

const initialState: DbState = {
  data: {},
};

const dbSlice = createSlice({
  name: 'dbReducer',
  initialState,
  reducers: {
    addRecords(state: DbState, action: PayloadAction<Record<string, unknown>>) {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
});

export const { addRecords } = dbSlice.actions;

export const dbReducer = dbSlice.reducer;

const getState = (state: RootState) => state.db;

export const getDbData = createSelector(getState, (state) => state.data);

export const getUsers = createSelector(getDbData, (db): UserEntity[] | null => {
  return build(db, RecordType.User);
});

export const getUser = createSelector(getUsers, (users): UserEntity | undefined => head(users));

export const getUserByIdFn = createSelector(
  getDbData,
  (db): ((userId: string) => UserEntity | null) => {
    return (userId: string) => build(db, RecordType.User, userId);
  },
);

export const getUserId = createSelector(getUser, (user): string | undefined =>
  !isUndefined(user) ? user.id : undefined,
);

export const getActivation = createSelector(getDbData, (db): ActivationEntity | undefined => {
  const data = build(db, RecordType.Activation);

  return head(data);
});

export const getProfile = createSelector(getDbData, (db): ProfileEntity | undefined => {
  const data = build(db, RecordType.Profile);

  return head(data);
});

export const getLogin = createSelector(getDbData, (db): LoginEntity | undefined => {
  const data = build(db, RecordType.Login);

  return head(data);
});

export const getCard = createSelector(getDbData, (db): CardEntity | undefined => {
  const data = build(db, RecordType.Card);

  return head(data);
});

export const getReferral = createSelector(getDbData, (db): ReferralEntity | undefined => {
  const data = build(db, RecordType.Referral);

  return head(data);
});

export const getBalance = createSelector(getDbData, (db): BalanceEntity | undefined => {
  const data = build(db, RecordType.Bonuses);

  return last(data);
});
