import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { CrossButton } from '../../components/CrossButton';
import { useNavigate } from '../navigation/router';
import { routes } from '../../domain/routes';
import { getProfile } from '../dbStore/dbStore';
import { changeProfile } from './userSaga';
import { useDispatchWithHistory } from '../actionWithHistory';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';
import { regExpIsNameValid } from './utils';
import { useForm } from 'react-hook-form';

type TFormData = { firstName: string; lastName: string };

export const ChangeProfilePage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const { t } = useTranslation('translation', { keyPrefix: 'changeProfile' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { control, handleSubmit, formState } = useForm<TFormData>({
    mode: 'onBlur',
    defaultValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
    },
  });

  const updateName = ({ firstName, lastName }: TFormData) => {
    dispatch(changeProfile, {
      firstName,
      lastName,
    });
  };

  const handleBack = () => {
    navigate(routes.profile);
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={handleSubmit(updateName)} isDisabled={!formState.isValid}>
            {t('buttonLabel')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(updateName)}>
        <CrossButton onClick={handleBack} />
        <div className="l-row" />
        <div className="l-row">
          <div className="c-title">{t('title')}</div>
        </div>
        <div className="l-row">
          <Input
            rules={{
              required: {
                value: true,
                message: tError('isRequired', { label: t('firstNameLabel') as string }) as string,
              },
              pattern: { value: regExpIsNameValid, message: tError('firstNameError') as string },
            }}
            control={control}
            name="firstName"
            label={t('firstNameLabel') as string}
          />
        </div>
        <div className="l-row">
          <Input
            rules={{
              required: {
                value: true,
                message: tError('isRequired', { label: t('lastNameLabel') as string }) as string,
              },
              pattern: { value: regExpIsNameValid, message: tError('lastNameError') as string },
            }}
            control={control}
            name="lastName"
            label={t('lastNameLabel') as string}
          />
        </div>
      </form>
    </Layout>
  );
};
