import { getCtx } from '../diContext';
import { activationMode } from '../../config/signupConfig';

export const acceptTermsAPI = (referralId?: string) => {
  const { sdk } = getCtx();
  return sdk.signup.acceptTerms(activationMode, referralId);
};

export const setProfileAPI = (data: { firstName: string; lastName: string; userId?: string }) => {
  const { sdk } = getCtx();
  return sdk.signup.setProfile(data, activationMode);
};

export const skipProfileAPI = () => {
  const { sdk } = getCtx();
  return sdk.signup.skipProfile(activationMode);
};

export const setPhoneAPI = (phoneNumber: string) => {
  const { sdk } = getCtx();
  return sdk.signup.setPhone(phoneNumber, activationMode);
};

export const activatePhoneAPI = (code: number) => {
  const { sdk } = getCtx();
  return sdk.signup.activatePhone(code, activationMode);
};

export const resendActivationCodeAPI = () => {
  const { sdk } = getCtx();
  return sdk.signup.resendActivationCode();
};

export const setEmailAPI = (email: string) => {
  const { sdk } = getCtx();
  return sdk.signup.setEmailWithoutActivation(email, activationMode);
};

export const skipEmailAPI = () => {
  const { sdk } = getCtx();
  return sdk.signup.skipEmail(activationMode);
};

export const skipEmailActivationAPI = () => {
  const { sdk } = getCtx();
  return sdk.signup.skipEmailActivation(activationMode);
};

export const activateEmailAPI = () => {
  const { sdk } = getCtx();
  return sdk.signup.activateEmail(activationMode);
};
