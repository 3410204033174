import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';

import { routes } from '../../domain/routes';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import { getBalance, getCard, getProfile } from '../dbStore/dbStore';
import { getBalanceHistory, getUserCard } from './userSaga';
import { useAppDispatch } from '../store/hooks';
import { getIsAuthenticated, logout } from '../auth/authStore';
import { downloadAndroidCardAction, downloadIOSCardAction } from '../card/cardStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { subscribeToPushManager } from '../subscriptionNotifications';
import { titles } from '../../config/titles';
import { ButtonDownloadCard } from '../card/ButtonDownloadCard';
import { calculateDiscount, isUserAndroid, isUserIos } from './utils';
import { isUserActionForPushSubscriptionNeeded } from '../../service-worker/notifications';
import { isNotificationsViewed } from '../../service-worker/helpers';
import { IMessageBus } from '@roketus/web-toolkit';
import { getDependency } from '../diContext';
import {
  DEMO_LOGOUT_CLICK,
  DEMO_NAME_UPDATE_CLICK,
  DEMO_PHONE_UPDATE_CLICK,
} from '../../domain/specs/analyticsActionCodes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../navigation/router';
import { Barcode } from '../../components/Barcode';
import RegisterServiceWorker from '../../service-worker/register';

const isWithPushButton = isUserActionForPushSubscriptionNeeded() && !isNotificationsViewed();

const { issuerTitle } = titles;
export const ProfilePage: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useSelector(getProfile);
  const balance = useSelector(getBalance);
  const isAuth = useSelector(getIsAuthenticated);
  const card = useSelector(getCard);
  const isLoading = isUndefined(profile);

  const firstName: string = profile?.firstName || '',
    lastName: string = profile?.lastName || '',
    expirationDate: Date | null = card ? new Date(card.validTill * 1000) : null;

  const messageBus = getDependency('messageBus') as IMessageBus;

  useRedirectOnAuthStepChangeEffct();

  useEffect(() => {
    if (!isWithPushButton) {
      subscribeToPushManager();
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(getUserCard());
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getBalanceHistory());
    }
  }, [isAuth, dispatch]);

  const downloadIOSCard = useCallback(() => {
    dispatch(downloadIOSCardAction());
  }, [dispatch]);

  const downloadAndroidCard = useCallback(() => {
    dispatch(downloadAndroidCardAction());
  }, [dispatch]);

  const handleOnUpdateProfileCLick = useCallback(() => {
    messageBus.send({
      type: 'GAanalyticsEvent',
      message: DEMO_NAME_UPDATE_CLICK,
      source: 'ProfilePage',
    });

    navigate(routes.profileChangeName);
  }, [navigate, messageBus]);

  const handleOnUpdatePhoneCLick = useCallback(() => {
    messageBus.send({
      type: 'GAanalyticsEvent',
      message: DEMO_PHONE_UPDATE_CLICK,
      source: 'ProfilePage',
    });

    navigate(routes.profileChangePhone);
  }, [messageBus, navigate]);

  const handleOnSubscribeCLick = useCallback(() => {
    subscribeToPushManager();
  }, []);

  const isDateExpired = () => {
    if (isNull(expirationDate)) return false;
    return expirationDate.getTime() < new Date().getTime();
  };

  const runOnce = useRef(true);
  useEffect(() => {
    if (runOnce.current) {
      runOnce.current = false;
      RegisterServiceWorker();
    }
  }, []);

  return (
    <Layout>
      {isLoading && <Loader isActive={true} />}
      {!isLoading && (
        <>
          <div className="l-row"></div>
          <div className="l-row l-row--mt-80">
            <div className="c-title c-title--fs-14">{t('subtitle', { name: firstName })}</div>
          </div>
          <div className="l-row l-row--tl">
            <div className="c-text c-text--b">Доступні бонуси: {balance?.totalActive.uah}</div>
          </div>
          <div className="l-row l-row--tl">
            <div className="c-text">{t('mainText', { issuerTitle })}</div>
          </div>
          <div className="l-row l-row--tl">
            <div className="c-text c-text--gray">100 бонусів = 100 грн</div>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-line" />
          </div>
          <div className="l-row l-row-60 l-row--flex l-row--jc-sb">
            <div className="c-title c-title--fs-14 c-title--gray">Ім'я</div>
            <div className="c-title c-title--fs-14 c-title--gray">Відсоток до зарахувань</div>
          </div>
          <div className="l-row l-row--mt-16 l-row--flex l-row--jc-sb">
            <div className="c-title">
              {firstName} {lastName}
            </div>
            <div className="c-title">{calculateDiscount(card?.discountPercent)}%</div>
          </div>

          <div className="l-row l-row--mt-16">
            <div className="c-text c-text--gray">номер персональної карти</div>
          </div>
          <div className="l-row l-row--mt-8">
            <div className="c-title">{card?.cardNumber}</div>
          </div>

          <div className="l-row l-row--mt-16">
            <Barcode message={`https://dashboard.roket.us/pos/${card?.cardNumber}`} />
          </div>

          <div className="l-row l-row--mt-16 c-download-buttons">
            <ButtonDownloadCard
              data-id="download-card-link"
              onClick={downloadIOSCard}
              device="iOS"
              data-display={isUserIos() ? 'button' : 'link'}
            >
              {t('downloadCardForIos')}
            </ButtonDownloadCard>

            <ButtonDownloadCard
              data-id="download-card-link"
              onClick={downloadAndroidCard}
              device="android"
              data-display={isUserAndroid() ? 'button' : 'link'}
            >
              {t('downloadCardForAndroid')}
            </ButtonDownloadCard>
          </div>

          <div className="l-row">
            <div className="c-text">
              Щоб використати бонуси під час покупки, необхідно зазначити цю інформацію у полі
              “Спеціальні інструкції для продавця” та обрати “Оплата при отриманні замовлення” на
              сайті або проговорити у розмові з менеджером.
            </div>
          </div>
          <div className="l-row">
            <div className="c-text">Бонусами можна оплатити до 35% загальної суми.</div>
          </div>
          <div className="l-row">
            <a href="https://api.rk01-demo.roket.us/link/go/iRdBtGDF" className="c-link">
              <span className="c-link__inner">
                Коротко про умови нарахування <br />
                та використання бонусів
              </span>
            </a>
          </div>
          <div className="l-row l-row--mt-16">
            <iframe
              src="https://www.instagram.com/mashsh.wear/embed/"
              width="100%"
              height={500}
              style={{ border: 'none' }}
            />
          </div>

          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="update-name-card-link"
              onClick={handleOnUpdateProfileCLick}
            >
              {t('updateName')}
            </button>
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="update-phone-number-link"
              onClick={handleOnUpdatePhoneCLick}
            >
              {t('updatePhone')}
            </button>
          </div>
          <div className="l-row l-row--mt-16">
            {isWithPushButton && (
              <div className="l-row l-row--mt-16">
                <button className="c-link c-link--blue" onClick={handleOnSubscribeCLick}>
                  {t('subscribe')}
                </button>
              </div>
            )}
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="logout-link"
              onClick={() => {
                messageBus.send({
                  type: 'GAanalyticsEvent',
                  message: DEMO_LOGOUT_CLICK,
                  source: 'ProfilePage',
                });

                dispatch(logout());
              }}
            >
              {t('logout')}
            </button>
          </div>
          <div className="l-row">
            <div className="c-text c-text--gray">До покупок!</div>
            <a className="c-title c-title--fs-14 c-link" href="https://mashshwear.com/">
              mashshwear.com
            </a>
          </div>
        </>
      )}
    </Layout>
  );
};
