import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { routes } from '../../domain/routes';
import { DevListPage } from '../../DevListPage';
import { SetIssuerPage } from '../user/SetIssuerPage';
import { ActivationUser } from '../user/ActivationUser';
import { SetupProfilePage } from '../signup/SetupProfilePage';
import { TermsPage as SignupTermsPage } from '../signup/TermsPage';
import { DownloadCardPage } from '../card/DownloadCardPage';
import { DownloadCardByReferralPage } from '../card/DownloadCardByReferralPage';
import { SignInPage } from '../auth/SignInPage';
import { ValidateOtpPage } from '../auth/ValidateOtpPage';
import { SignInByJWT } from '../auth/SignInByJWT';
import { ProfilePage as Profile } from '../user/ProfilePage';
import { ChangePhonePage } from '../user/ChangePhonePage';
import { ChangePhoneActivationPage } from '../user/ChangePhoneActivationPage';
import { ChangeEmailPage } from '../user/ChangeEmailPage';
import { ChangeProfilePage } from '../user/ChangeProfilePage';
import { InviteFriendPage } from '../user/InviteFriendPage';
import { IfProfileActivation } from './IfProfileActivation';
import { SetPhonePage } from '../signup/SetPhonePage';
import { PhoneActivationPage } from '../signup/PhoneActivationPage';
import { PhoneActivationSuccessPage } from '../signup/PhoneActivationSuccessPage';
import { EmailVerificationPage } from '../signup/EmailVerificationPage';
import { CheckInboxPage } from '../signup/CheckInboxPage';
import { EmailVerificationSuccessPage } from '../signup/EmailVerificationSuccessPage';
import { EmailVerificationErrorPage } from '../signup/EmailVerificationErrorPage';
import { IfAuthorized } from './IfAuthorized';
import { SetBirthdayPage } from '../signup/SetBirthdayPage/SetBirthdayPage';

export const Navigation = () => {
  return (
    <Routes>
      {/* Public pages */}
      <Route path={routes.home} element={<SignInPage />} />
      <Route path={routes.dev} element={<DevListPage />} />
      <Route path={routes.setIssuer} element={<SetIssuerPage />} />
      <Route path={routes.activationUser} element={<ActivationUser />} />
      <Route path={routes.signupProfile} element={<SetupProfilePage />} />
      <Route path={routes.signupTermsByReferral} element={<SignupTermsPage />} />
      <Route path={routes.signupTerms} element={<SignupTermsPage />} />
      <Route path={routes.downloadCard} element={<DownloadCardPage />} />
      <Route path={routes.downloadCardByReferral} element={<DownloadCardByReferralPage />} />
      <Route path={routes.signIn} element={<SignInPage />} />
      <Route path={routes.signInvalidatePhoneOtp} element={<ValidateOtpPage />} />
      <Route path={routes.signInJWT} element={<SignInByJWT />} />

      {/* Activation flow pages */}
      <Route
        path={routes.signupSetPhone}
        element={
          <IfProfileActivation>
            <SetPhonePage />
          </IfProfileActivation>
        }
      />
      <Route
        path={routes.signupPhoneActivation}
        element={
          <IfProfileActivation>
            <PhoneActivationPage />
          </IfProfileActivation>
        }
      />
      <Route
        path={routes.signupPhoneActivationSuccess}
        element={
          <IfProfileActivation>
            <SetupProfilePage />
          </IfProfileActivation>
        }
      />
      <Route
        path={routes.signupSetEmail}
        element={
          <IfProfileActivation>
            <EmailVerificationPage />
          </IfProfileActivation>
        }
      />
      <Route
        path={routes.signupSetBirthday}
        element={
          <IfProfileActivation>
            <SetBirthdayPage />
          </IfProfileActivation>
        }
      />
      <Route
        path={routes.signupCheckInbox}
        element={
          <IfProfileActivation>
            <CheckInboxPage />
          </IfProfileActivation>
        }
      />
      <Route
        path={routes.signupVerifyEmailSuccess}
        element={
          <IfProfileActivation>
            <EmailVerificationSuccessPage />
          </IfProfileActivation>
        }
      />
      <Route
        path={routes.signupVerifyEmailError}
        element={
          <IfProfileActivation>
            <EmailVerificationErrorPage />
          </IfProfileActivation>
        }
      />

      {/* Auth pages */}
      <Route
        path={routes.profile}
        element={
          <IfAuthorized>
            <Profile />
          </IfAuthorized>
        }
      />
      <Route
        path={routes.profileChangePhone}
        element={
          <IfAuthorized>
            <ChangePhonePage />
          </IfAuthorized>
        }
      />
      <Route
        path={routes.profileChangePhoneActivation}
        element={
          <IfAuthorized>
            <ChangePhoneActivationPage />
          </IfAuthorized>
        }
      />
      <Route
        path={routes.profileChangeEmail}
        element={
          <IfAuthorized>
            <ChangeEmailPage />
          </IfAuthorized>
        }
      />
      <Route
        path={routes.profileChangeName}
        element={
          <IfAuthorized>
            <ChangeProfilePage />
          </IfAuthorized>
        }
      />
      <Route
        path={routes.profileInviteFriend}
        element={
          <IfAuthorized>
            <InviteFriendPage />
          </IfAuthorized>
        }
      />

      <Route
        path="/"
        element={
          <IfAuthorized>
            <Profile />
          </IfAuthorized>
        }
      />
    </Routes>
  );
};
