import { call, put, takeLatest } from 'redux-saga/effects';
import {
  changeEmailAPI,
  changePhoneAPI,
  validateNewPhoneOtpAPI,
  changeProfileAPI,
  sendPushNotificationAPI,
} from '../api/user';
import { createAction, PayloadAction, ActionCreatorWithPayload } from '@reduxjs/toolkit';

import {
  ChangeEmailActionPayload,
  ChangePhoneActionPayload,
  ActivateNewPhoneActionPayload,
  ChangeProfileActionPayload,
  SendPushActionPayload,
} from '../../boundries/actionsPayloads/userActionPayloads';
import { ActionWithHistory, createActionWithHistory } from '../actionWithHistory';
import { routes } from '../../domain/routes';
import { handleError } from '../error/utils';
import { updateUserData } from '../auth/authStore';
import { generatePath } from '../navigation/router';
import { JsonApiResponseDTO } from '../../boundries/responseDTO/jsonApi';
import { finishRequest, startRequest } from '../global/globalStore';
import { saveJsonApiResponseToDb, saveJsonApiResponseToDbFunction } from '../sharedSagas';
import { getBalanceHistoryAPI, getReferralAPI, getUserCardAPI } from '../api/card';
import { head } from 'lodash';
import { IHTTPClientResponse } from '@roketus/web-toolkit';
import { IGetBonuses } from '@roketus/loyalty-js-sdk/dist/cjs/interfaces/card/IGetBonuses';

export const changePhone = createActionWithHistory<ChangePhoneActionPayload>('user/changePhone');
export const activatePhone =
  createActionWithHistory<ActivateNewPhoneActionPayload>('user/activatePhone');
export const changeNotActivePhone: ActionCreatorWithPayload<ChangePhoneActionPayload> =
  createAction('user/changeNotActivePhone');
export const changeEmail = createActionWithHistory<ChangeEmailActionPayload>('user/changeEmail');
export const changeProfile =
  createActionWithHistory<ChangeProfileActionPayload>('user/changeProfile');
export const getUserCard = createAction('user/getUserCard');
export const inviteFriend = createAction('user/inviteFriend');
export const sendPush = createAction<SendPushActionPayload>('auth/push');
export const getBalanceHistory = createAction('user/getBalanceHistory');

function* changePhoneSaga(action: PayloadAction<ActionWithHistory<ChangePhoneActionPayload>>) {
  try {
    const {
      data: { phoneNumber },
      navigate,
    } = action.payload;
    yield put(startRequest());
    yield call(changePhoneAPI, phoneNumber);

    const redirectUrl = generatePath(routes.profileChangePhoneActivation, { phoneNumber });
    yield call(navigate, redirectUrl);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* activatePhoneSaga(
  action: PayloadAction<ActionWithHistory<ActivateNewPhoneActionPayload>>,
) {
  try {
    const {
      data: { code },
      navigate,
    } = action.payload;
    yield put(startRequest());
    yield call(validateNewPhoneOtpAPI, parseInt(code, 10));
    yield put(updateUserData());
    yield call(navigate, routes.profile);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* changeNotActivePhoneSaga(action: PayloadAction<ChangePhoneActionPayload>) {
  try {
    const { phoneNumber } = action.payload;
    yield put(startRequest());
    yield call(changePhoneAPI, phoneNumber);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* changeEmailSaga(action: PayloadAction<ActionWithHistory<ChangeEmailActionPayload>>) {
  try {
    const {
      data: { email },
      navigate,
    } = action.payload;
    yield put(startRequest());
    yield call(changeEmailAPI, email);
    yield put(updateUserData());
    yield call(navigate, routes.signupCheckInbox);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* changeProfileSaga(action: PayloadAction<ActionWithHistory<ChangeProfileActionPayload>>) {
  try {
    const {
      data: { firstName, lastName },
      navigate,
    } = action.payload;
    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<unknown>> = yield call(
      changeProfileAPI,
      firstName,
      lastName,
    );

    yield call<saveJsonApiResponseToDbFunction>(saveJsonApiResponseToDb, response.data);
    yield call(navigate, routes.profile);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* getUserCardSaga() {
  try {
    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<unknown>> = yield call(getUserCardAPI);

    yield call<saveJsonApiResponseToDbFunction>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* getBalanceHistorySaga() {
  try {
    yield put(startRequest());
    const response: Awaited<ReturnType<IGetBonuses>['result']> = yield call(getBalanceHistoryAPI);

    if (head(response.jsonAPI.data)) {
      // @ts-ignore
      yield call<saveJsonApiResponseToDbFunction>(saveJsonApiResponseToDb, response.jsonAPI);
    }
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* inviteFriendSaga() {
  try {
    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<unknown>> = yield call(getReferralAPI);
    yield call<saveJsonApiResponseToDbFunction>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* sendPushSaga(action: PayloadAction<SendPushActionPayload>) {
  try {
    const { userId, title, message } = action.payload;
    yield call(sendPushNotificationAPI, userId, title, message);
  } catch (e) {
    yield call(handleError, e as Error);
  }
}

export function* userSaga() {
  yield takeLatest(changePhone, changePhoneSaga);
  yield takeLatest(activatePhone, activatePhoneSaga);
  yield takeLatest(changeNotActivePhone, changeNotActivePhoneSaga);
  yield takeLatest(changeEmail, changeEmailSaga);
  yield takeLatest(changeProfile, changeProfileSaga);
  yield takeLatest(getUserCard, getUserCardSaga);
  yield takeLatest(inviteFriend, inviteFriendSaga);
  yield takeLatest(sendPush, sendPushSaga);
  yield takeLatest(getBalanceHistory, getBalanceHistorySaga);
}
